<template>
  <div>
    <DataTable
        :getQuery="queries.get"
        :removeQuery="queries.remove"
        :updateQuery="queries.update"
        :connector="connector"
        :headers="headers"
        :createFields="createFields"
        :where="where"
        :hide_add_button="true"
        icon="mdi-home"
        result_name="products"
        title="company products"
        subtitle="List of all items"
    ></DataTable>
  </div>
</template>
<script>
import DataTable from '@/components/datatable'
import {mapGetters} from "vuex";
import {mapActions} from "vuex";
export default {
  components: {DataTable},

  computed:{
    ...mapGetters({
      getById: "getCompanyById"
    })
  },

  methods:{
    ...mapActions({
      retrieveCompanies: "allCompanies"
    }),
  },

  mounted() {
    let id = this.$route.params.id
    if(id){
      this.connector = {
        company: {
          connect: {id}
        }
      };

      this.where = {
        company: {id}
      }
    }
    else{
      this.$router.go(-1)
    }
  },

  data: () => ({
    queries: require('../../../gql/product.gql'),
    connector: {},
    where: {},
    companyId: '',
    createFields:[
      {name:'name', label: 'Name', icon: 'mdi-account'},
    ],
    headers: [
      { text: 'Name', align: 'left', value: 'name' },
      { text: 'Product Type', align: 'left', value: 'product_type.name' },
      { text: 'Category', align: 'left', value: 'category.name'},
      { text: 'Actions', value: 'action', sortable: false },
    ],
  }),
}
</script>
